<template>
    <div class="text-center">
		<v-dialog
			v-model="showFiles"
			transition="dialog-top-transition"
			persistent
			:fullscreen="$vuetify.breakpoint.mobile"
		>		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					<v-row>
						<v-col
							cols="12"
							xl="4"
							lg="4"
							md="6"
							sm="12"
						>
							{{ setTitle }}
						</v-col>
						<v-col
							v-if="showFilters"
							cols="12"
							xl="4"
							lg="4"
							md="6"
							sm="12"
							style="text-align: center;"
						>
							<v-btn
								class="mx-2"
								outlined
								style="font-size: 12px !important;"
								@click="showDialogNoteAndClearFields()"
							>
								Upload
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>

				<v-card-text style="margin-top: 20px;">

					<v-row
						v-if="showAsCarrousel"
					>
						<v-col
							cols="12"
						>
							<v-row v-if="showFilters">
								<v-col 
									cols="12"
									xl="4"
									lg="4"
									md="4"
									sm="12"
								>
									<label>Module</label>
									<v-combobox
										v-model="moduleSelected"
										:items="listModule"
										item-text="description"
										item-value="description"
										clearable
										outlined
										dense
										@change="filterPhotos"
									></v-combobox>
								</v-col>
								<v-col 
									cols="12"
									xl="4"
									lg="4"
									md="4"
									sm="12"
								>
									<label>Stage</label>
									<v-combobox
										v-model="stageSelected"
										:items="listStage"
										item-text="description"
										item-value="description"
										clearable
										outlined
										dense
										@change="filterPhotos"
									></v-combobox>
								</v-col>
								<v-col
									cols="12"
									xl="4"
									lg="4"
									md="4"
									sm="12"
								>
									<label>Quick Search</label>
									<v-text-field
										v-model="fastSearch"
										prepend-inner-icon="mdi mdi-magnify"
										single-line
										outlined
										dense
									></v-text-field>
								</v-col>
							</v-row>

							<div
								class="divThumbnails"
							>
								<div
									class="divPicturesThumb"
									v-for="(itemPhoto, index) in listPhotosComputedFiltered"
									:key="index"
								>
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-card
												style="display: flex; flex-direction: column; align-items: center;"
												elevation="2"
												@click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
											>
												<v-img
													:src="itemPhoto.src"
													:lazy-src="itemPhoto.src"
													aspect-ratio="1"
													max-height="170"
													max-width="170"
													v-on="on"
												>
													<template v-slot:placeholder>
														<v-row
															class="fill-height ma-0"
															align="center"
															justify="center"
														>
															<v-progress-circular
																indeterminate
																color="grey lighten-5"
															></v-progress-circular>
														</v-row>
													</template>
												</v-img>
												<v-list style="width: min-content;">
													<v-list-item>
														<v-list-item-content>
															<v-list-item-title><strong>{{ itemPhoto.stageDescription }}</strong></v-list-item-title>
															<v-list-item-title style="font-size: 0.9rem;" v-if="itemPhoto.typeAttachmentDescription != ''">{{ itemPhoto.typeAttachmentDescription }} - [{{ itemPhoto.typeDocumentDescription }}]</v-list-item-title>
															<v-list-item-subtitle>{{ itemPhoto.fileName }}</v-list-item-subtitle>
															<v-list-item-subtitle>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>
												</v-list>
											</v-card>
										</template>
										<span>Click to Download</span>
									</v-tooltip>
								</div>
							</div>
						</v-col>
					</v-row>

					<v-row
						v-else-if="listPhotos != null && listPhotos != undefined && listPhotos.length > 0"
					>
						<v-col
							cols="12"
							lg="3"
							xl="2"
							md="5"
							sm="12"
							v-for="(itemPhoto, index) in listPhotos"
							:key="index"
						>
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-card
										class="cardDownload"
										v-on="on"
										@click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
									>
										<v-card-title class="cardTitlePhotosDownload">
											<label>{{ itemPhoto.fileName }}</label>
										</v-card-title>

										<v-card-text
											v-if="itemPhoto.fileType.toString().toLowerCase().includes('pdf')"
											style="height: 245px; text-align: center;"
										>
											<v-icon x-large style="margin-top: 77px;">mdi mdi-file-pdf-box</v-icon>
											<br />
											<label>PDF File</label>
											<br />
											<span style="font-size: 10px; position: relative; top: 74px;">{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
										</v-card-text>
										<v-card-text
											v-else
										>
											<v-img
												:src="itemPhoto.src"
												:lazy-src="itemPhoto.src"
												aspect-ratio="1"
												max-height="200"
												max-width="250"
												class="cardImagePhotosDownload"
											>
												<template v-slot:placeholder>
												<v-row
													class="fill-height ma-0"
													align="center"
													justify="center"
												>
													<v-progress-circular
														indeterminate
														color="grey lighten-5"
													></v-progress-circular>
												</v-row>
												</template>
											</v-img>
											<span style="font-size: 10px;">{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
										</v-card-text>
									</v-card>
								</template>
								<span>Click to Download</span>
							</v-tooltip>
						</v-col>
					</v-row>

                    <v-row
						v-if="listFiles.length > 0"
					>
                        <v-col  
                            lg="12"
                        >
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>

					<v-row
						v-if="listFiles != null && listFiles != undefined && listFiles.length > 0"
					>
						<v-col
							cols="12"
						>
							<label>Files</label>
						</v-col>
						<v-col
							v-for="(itemFile, index) in listFiles"
							:key="index"
							cols="12"
							lg="2"
							md="6"
							sm="12"
						>
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-card
										class="cardDownload"
										v-on="on"
										@click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
									>
										<v-card-title class="cardTitlePhotosDownload">
											<label 
												v-if="itemFile.typeDocumentDescription != null && itemFile.typeDocumentDescription != undefined && itemFile.typeDocumentDescription != ''"
												style="color: #ccc;"
											>
												{{ itemFile.typeDocumentDescription }}
											</label>
											<br />
											<label>{{ itemFile.fileName }}</label>
										</v-card-title>

										<v-card-subtitle>
											<span>{{ itemFile.createdOn }} by {{ itemFile.userName }}</span>
										</v-card-subtitle>

										<v-card-text>
											<v-icon x-large>mdi mdi-file-download-outline</v-icon>
											<span class="imageFileName">{{ itemFile.fileName }}</span>
										</v-card-text>
									</v-card>
								</template>
								<span>Click to Download</span>
							</v-tooltip>
							
						</v-col>
					</v-row>

					<v-row
						v-if="listFiles.length <= 0 && listPhotos.length <= 0"
					>
						<v-col
							cols="12"
						>
							<label>{{ label }}</label>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonDismiss"
							outlined
							@click="dismiss()"
						>
							Dismiss
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>


		<v-dialog
			style="overflow: hidden !important;"
			v-model="showDialogNote"
			transition="dialog-top-transition"
			persistent
			width="70%"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
					Upload Photo
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
					<v-form
						ref="formUploadPhoto"
						v-model="validFormUploadPhoto"
						lazy-validation
					>
						<v-row>
							<v-col
								cols="12"
								lg="4"
								md="12"
								sm="12"
							>
								<label>Department</label>
								<v-combobox
									v-model="noteModuleSelected"
									:items="listModules" 
									:rules=[validations.required]
									item-text="moduleDescription"
									item-value="id"
									clearable
									outlined
									dense
									@change="filterStages(true)"
								></v-combobox>
							</v-col>
							<v-col
								cols="12"
								lg="4"
								md="12"
								sm="12"
							>
								<label>Stage</label>
								<v-combobox
									v-model="noteStageSelected"
									:items="listStatusFiltered"
									item-text="statusDescription"
									item-value="id"
									clearable
									outlined
									dense
								></v-combobox>
							</v-col>
							<v-col
								cols="12"
								lg="4"
								md="12"
								sm="12"
							>
								<label>Product</label>
								<v-combobox
									v-model="noteProductSelected"
									:items="listProjectItems"
									item-text="description"
									item-value="idProduct"
									clearable
									outlined
									dense
								></v-combobox>
							</v-col>
						</v-row>

						<v-row>
							<v-col
								cols="12"
							>
								<canvas id="canvas" hidden></canvas>
								<label>Photos</label>
								<v-file-input
									v-model="noteFilesPhotos" 
									accept="image/*"
									small-chips
									multiple
									outlined
									placeholder="Click here to add"
									prepend-inner-icon="mdi-paperclip"
									prepend-icon=""
									@change="resizePreviewImage(noteFilesPhotos, notePhotos)"
								></v-file-input>
							</v-col>
						</v-row>

						<v-row
							:style="notePhotos != null && notePhotos != undefined && notePhotos.length > 0 ? 'margin-bottom: 20px;' : ''"
						>
							<v-col
								v-for="(itemPhoto, index) in notePhotos.filter(pht => pht.deleted == false)"
								:key="index"
								cols="12"
								xl="2"
								lg="3"
								md="4"
								sm="12"
							>
								<v-card max-width="200">
									<v-img
										:src="itemPhoto.src"
										:lazy-src="itemPhoto.src"
										aspect-ratio="1"
										max-height="200"
										max-width="200"
									>
										<template v-slot:placeholder>
										<v-row
											class="fill-height ma-0"
											align="center"
											justify="center"
										>
											<v-progress-circular
												indeterminate
												color="grey lighten-5"
											></v-progress-circular>
										</v-row>
										</template>
									</v-img>
									<span class="imageFileName">{{ itemPhoto.fileName }}</span>
									<v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(itemPhoto)">mdi mdi-delete</v-icon>
								</v-card>
							</v-col>
						</v-row>


						<v-row>
							<v-col 
								cols="12"
								lg="12"
								md="12"
								sm="12"
							>
								<label>Note</label>
								
								<v-textarea
									v-model="note"
									:rules=[validations.required]
									single-line
									rows="3"
									row-height="30"
									outlined
								>
								</v-textarea>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 buttonCancelDialog"
							style=""
							outlined
							@click="showDialogNote = false"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
							@click="saveNote()"
						>
							Save
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { URL_ATTACHMENT } from "@/utilities/Enums";
    import { orderBy } from '@/utilities/Utils';
    import { required } from "@/utilities/Rules";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT } from "@/utilities/Enums";

	export default {

        mixins: [Helpers],

        props: {

			idServiceProject: {
				type: Number,
				default: 0
			},

			reload: {
				type: Number,
				default: 0
			},

			title: {
				type: String,
				default: null
			},

			showFiles: {
				type: Boolean,
				default: false
			},

			listParams: {
				type: Array,
				default: null
			},

			showAsCarrousel: {
				type: Boolean,
				default: false
			},

			showFilters: {
				type: Boolean,
				default: true
			},
		},

		
        data: () => ({

			URL_ATTACHMENT,

			loading: false,
			listFiles: [],
			listPhotos: [],
			listPhotosFiltered: [],
			label: "",

			moduleSelected: null,
			listModule: [],

			stageSelected: null,
			listStage: [],

			fastSearch: '',
			
			validFormUploadPhoto: false,
            showDialogNote: false,
            listModules: [],
            listStatus: [],
            listStatusFiltered: [],
            listProjectItems: [],
			noteModuleSelected: null,
            noteStageSelected: null,
            noteProductSelected: null,
            noteFilesPhotos: [],
            notePhotos: [],
            note: "",
            
            validations: {
                required: required
            }	
		}),

        watch: {
            async "reload"() {
				this.clearFields();
				this.getFiles();
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            listPhotosComputedFiltered: function() {
                return this.listPhotosFiltered.filter((itemFilter) => {
                    
                    let filter = this.fastSearch.toLowerCase();

                    var fileName = itemFilter.fileName != null && itemFilter.fileName != undefined ? itemFilter.fileName.toLowerCase().match(filter) : ''
                    var fileType = itemFilter.fileType != null && itemFilter.fileType != undefined ? itemFilter.fileType.toLowerCase().match(filter) : ''
                    var dateRegisterFormatted = itemFilter.dateRegisterFormatted != null && itemFilter.dateRegisterFormatted != undefined ? itemFilter.dateRegisterFormatted.toLowerCase().match(filter) : ''
                    var userNameRegister = itemFilter.userNameRegister != null && itemFilter.userNameRegister != undefined ? itemFilter.userNameRegister.toLowerCase().match(filter) : ''
                    var typeAttachmentDescription = itemFilter.typeAttachmentDescription != null && itemFilter.typeAttachmentDescription != undefined ? itemFilter.typeAttachmentDescription.toString().toLowerCase().match(filter) : '';
                    var typeDocumentDescription = itemFilter.typeDocumentDescription != null && itemFilter.typeDocumentDescription != undefined ? itemFilter.typeDocumentDescription.toString().toLowerCase().match(filter) : '';
                    var stageDescription = itemFilter.stageDescription != null && itemFilter.stageDescription != undefined ? itemFilter.stageDescription.toString().toLowerCase().match(filter) : '';
					

                    if(stageDescription != null) { return stageDescription; } 
                    else if(typeAttachmentDescription != null) { return typeAttachmentDescription; }
                    else if(typeDocumentDescription != null) { return typeDocumentDescription; } 
                    else if(userNameRegister != null) { return userNameRegister; } 
                    else if(fileName != null) { return fileName; } 
                    else if(dateRegisterFormatted != null) { return dateRegisterFormatted; } 
                    else { return fileType; } 
                });
            },

			setTitle() {
				const defaultTitle = this.showAsCarrousel ? 'Gallery' : 'Files';

				if (this.title != null) {
					return this.title;
				}
				else {
					return defaultTitle;
				}
			}
        },

        methods: {

            async clearFields() {
				this.label = "Loading...";
				this.listFiles = [];
				this.listPhotos = [];
				this.listPhotosFiltered = [];

                this.listModules = await this.$store.dispatch("moduleModule/List");
                let listStatus = await this.$store.dispatch("serviceModule/ListStatus");
                this.listStatus = listStatus.filter(sts => sts.id >= 3).sort((a, b) => { return orderBy(a, b, 0, 'id') });

				this.listProjectItems = await this.$store.dispatch("serviceModule/ListItems", this.idServiceProject);
            },
			
            async dismiss() {				
				this.$emit('update:showFiles', false)
            },

			async getFiles() {

				if (this.listParams != null && this.listParams != undefined && this.listParams.length > 0) {

					this.listParams.forEach(async itemParam => {

						let listAttachments = await this.$store.dispatch("attachmentModule/GetAllByDocument", { 
							typeAttachment: itemParam.typeAttachment, 
							typeDocument: itemParam.typeDocument, 
							idKey: itemParam.idKey 
						});

						if (listAttachments != null && listAttachments != undefined) {
						
							listAttachments.forEach(itemPhoto => {

								if (itemParam.isImage || itemParam.isImageAndDoc) {

									this.listPhotos.push({
										id: itemPhoto.id,
										fileName: itemPhoto.fileName,
										fileType: itemPhoto.fileType,
										createdOn: itemPhoto.dateRegisterFormatted,
										userName: itemPhoto.userNameRegister,
										stageDescription: itemParam.stageDescription ?? '',
										typeAttachmentDescription: itemParam.typeAttachmentDescription == null || itemParam.typeAttachmentDescription == undefined ? itemPhoto.typeAttachmentDescription : itemParam.typeAttachmentDescription,
										typeDocument: itemPhoto.typeDocument,
										typeDocumentDescription: itemParam.typeDocumentDescription == null || itemParam.typeDocumentDescription == undefined ? itemPhoto.typeDocumentDescription : itemParam.typeDocumentDescription,
										src: `data:image/jpg;base64,${itemPhoto.file}`
									})
								}
								else {
									this.listFiles.push({
										id: itemPhoto.id,
										fileName: itemPhoto.fileName,
										fileType: itemPhoto.fileType,
										createdOn: itemPhoto.dateRegisterFormatted,
										userName: itemPhoto.userNameRegister,
										stageDescription: itemParam.stageDescription ?? '',
										typeAttachmentDescription: itemParam.typeAttachmentDescription == null || itemParam.typeAttachmentDescription == undefined ? itemPhoto.typeAttachmentDescription : itemParam.typeAttachmentDescription,
										typeDocument: itemPhoto.typeDocument,
										typeDocumentDescription: itemParam.typeDocumentDescription == null || itemParam.typeDocumentDescription == undefined ? itemPhoto.typeDocumentDescription : itemParam.typeDocumentDescription,
									})
								}
							});

							if (this.listPhotos.length > 0) {
								this.listPhotos.sort((a, b) => { return orderBy(a, b, 1, 'id') });

								this.listModule = this.listPhotos.map( (item) => item.stageDescription );
								this.listStage = this.listPhotos.map( (item) => item.typeAttachmentDescription );

								this.filterPhotos();
							}

							if (this.listFiles.length > 0) {
								this.listFiles.sort((a, b) => { return orderBy(a, b, 1, 'id') });
							}
						}

						if (listAttachments == null || listAttachments == undefined || listAttachments.length <= 0) {
							this.label = "There are no files to show";
						}
					})
				}
			},

			filterPhotos() {
				
                let moduleSelected = '';
                let stageSelected = '';
                let listFiltered = [...this.listPhotos];

                if (this.moduleSelected != null && this.moduleSelected != undefined) {
                    moduleSelected = this.moduleSelected;
                    listFiltered = listFiltered.filter(obj => obj.stageDescription == moduleSelected);
                }

                if (this.stageSelected != null && this.stageSelected != undefined) {
                    stageSelected = this.stageSelected;
                    listFiltered = listFiltered.filter(obj => obj.typeAttachmentDescription == stageSelected);
                }

                this.listPhotosFiltered = listFiltered.sort((a, b) => { return orderBy(a, b, 1, 'id') });
			},

			async clearFieldsNote() {

                this.noteModuleSelected = this.listModules[0];
                this.filterStages(true);
                this.note = "";
                this.notePhotos = [];
            },

            async showDialogNoteAndClearFields()  {

                this.clearFieldsNote();
                this.showDialogNote = true;
            },

            async showDialogNoteAndFillFields(id) {
                this.clearFieldsNote();
                this.itemNote = this.listNote.filter(not => not.id == id)[0];

                if (this.itemNote != null && this.itemNote != undefined) {

                    let moduleSelected = this.listModules.filter(mod => mod.id == this.itemNote.idModule)[0];
                    if (moduleSelected != null && moduleSelected != undefined) {
                        this.noteModuleSelected = moduleSelected;
                        this.filterStages(true);
                    }

                    let statusSelected = this.listStatusFiltered.filter(mod => mod.id == this.itemNote.idStatus)[0];
                    if (statusSelected != null && statusSelected != undefined) {
                        this.noteStageSelected = statusSelected;
                    }

                    let productSelected = this.listProjectItems.filter(mod => mod.idProduct == this.itemNote.idProduct)[0];
                    if (productSelected != null && productSelected != undefined) {
                        this.noteProductSelected = productSelected;
                    }
                    
                    let listAttachments = await this.$store.dispatch("attachmentModule/GetAllByDocument", { 
                        typeAttachment: TYPE_ATTACHMENT.PROJECT.value, 
                        typeDocument: TYPE_DOCUMENT.NOTE.value, 
                        idKey: id
                    });

                    listAttachments.forEach(itemPhoto => {

                        this.notePhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            type: itemPhoto.fileType,
                            fileName: itemPhoto.fileName,
                            fileType: itemPhoto.fileType,
                            createdOn: itemPhoto.dateRegisterFormatted,
                            userName: itemPhoto.userNameRegister,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        });
                    })
                    
                    this.note = this.itemNote.description;

                    this.showDialogNote = true;
                }
            },            

            async uploadFiles(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async addNotesPhotos(idNote) {

                let allResult = []

                this.notePhotos.forEach(async itemPhoto => {

                    let result = await this.uploadFiles(itemPhoto.id, idNote, itemPhoto.file, TYPE_DOCUMENT.NOTE.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.NOTE.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `Error to upload photos: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async saveNote() {

                await this.$refs.formUploadPhoto.validate();

                if (this.validFormNote === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the notes form. Please review!");
                    return false;
                }
                else {
                    
                    let id = 0;
                    let noteRequest = null;
                    let idServiceProject = this.idServiceProject;
                    let idProfile = this.userLoggedGetters.profile.id;
                    let idModule = 0;
                    let idStatus = 0;
                    let idProduct = 0;
                    let idPanel = 0;
                    let idStage = 0;
                    let showToCustomer = 0;
                    let classification = null;

                    if (this.noteModuleSelected != null && this.noteModuleSelected != undefined) {
                        idModule = this.noteModuleSelected.id;
                    }

                    if (this.noteStageSelected != null && this.noteStageSelected != undefined) {
                        idStatus = this.noteStageSelected.id;
                    }

                    if (this.noteProductSelected != null && this.noteProductSelected != undefined) {
                        idProduct = this.noteProductSelected.idProduct;
                    }

                    noteRequest = {
                        id,
                        idServiceProject,
                        idProfile,
                        idPanel,
                        idStage,
                        description: this.note.trim(),
                        showToCustomer,
                        expirationDate: null,
                        classification,
                        idStatus,
                        idModule,
                        idProduct
                    }

                    const result = await this.$store.dispatch("serviceModule/AddProjectNote", noteRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        await this.addNotesPhotos(result.idNote);
						
                        this.listParams.push({
                            stageDescription: this.noteStageSelected.statusDescription,
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                            typeDocument: TYPE_DOCUMENT.NOTE.value,
                            typeAttachmentDescription: '',
                            typeDocumentDescription: '',
                            idKey: result.idNote,
                            isImage: true
                        });

						setTimeout(() => {
							this.clearFields();
							this.getFiles();
						}, 1000);

                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.showDialogNote = false;
                }
            },

            filterStages(modalShowed) {
                let idModule = 0;

                if (modalShowed == true && this.noteModuleSelected != null && this.noteModuleSelected != undefined) {
                    idModule = this.noteModuleSelected.id;
                }
                else if (this.noteSearchModuleSelected != null && this.noteSearchModuleSelected != undefined) {
                    idModule = this.noteSearchModuleSelected.id;
                }

                if (idModule == 1) {
                    this.listStatusFiltered = [...this.listStatus.filter(sta => sta.id >= 3 && sta.id <= 7)]
                }
                else if (idModule == 2) {
                    this.listStatusFiltered = [...this.listStatus.filter(sta => sta.id >= 8 && sta.id <= 13)]
                }
                else if (idModule == 3) {
                    this.listStatusFiltered = [...this.listStatus.filter(sta => sta.id >= 14 && sta.id <= 16)]
                }
                else if (idModule == 4) {
                    this.listStatusFiltered = [...this.listStatus.filter(sta => sta.id >= 17 && sta.id <= 46)]
                }
                else if (idModule == 5) {
                    this.listStatusFiltered = [...this.listStatus.filter(sta => sta.id >= 47)]
                }
            }
        }
    };
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }
	
    .cardDownload {
        text-align: center;
		cursor: pointer;
		margin-bottom: 20px;
    }

    .cardTitleDownload {
        display: block !important;
        margin-bottom: 10px;
    }

    .cardTitlePhotosDownload {
		font-size: 14px;
        display: block !important;
    }

    .cardImagePhotosDownload {
        margin-bottom: 10px;
    }

	.v-item-v-carousel__controls__item > v-btn {
		color: silver !important;
	}
</style>