<template>
    <div>
        <v-dialog
            v-model="showDialogImportantMessage"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Message
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-textarea
                                v-model="importantMessage"
                                :rules=[validations.required]
                                rows="4"
                                row-height="23"
                                auto-grow
                                outlined
                            >
                            </v-textarea>

                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";

	export default {

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },

            message: {
                type: String,
                default: ''
            },

			showDialogImportantMessage: {
				default: false
			},
		},
		
        data: () => ({
			
            importantMessage: "",
            validations: {
                required: required
            }
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        watch: {

            async "id"() {
                this.importantMessage = this.message;
            },
        },

        methods: {

            cancel() {
                this.$emit('update:showDialogImportantMessage', false);
            },

            async confirm() {

                const serviceImportantMessageRequest = {
                    importantMessage: this.importantMessage
                }

                const result = await this.$store.dispatch("serviceModule/SetImportantMessage", { idService: this.id, serviceImportantMessageRequest });

                if (result.success === true) {
                    this.showToast("success", "Success!", "Updated successfully!");
                    this.$emit('methodConfirmToCall');
                    this.cancel();
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }
            }
        }
    };
</script>