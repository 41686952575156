<template>
    <div>
        <v-dialog
            v-model="showScopeOfWork"
            transition="dialog-top-transition"
            persistent
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Scope of Work
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        <v-row>
                                            <v-col
                                                cols="9"
                                            >
                                                Selected Items
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-simple-table
                                            fixed-header
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 10%">
                                                            Category / Sub Category
                                                        </th>
                                                        <th class="text-left" style="width: 20%">
                                                            Product
                                                        </th>
                                                        <th class="text-left" style="width: 20%">
                                                            Description
                                                        </th>
                                                        <th class="text-left" style="width: 10%">
                                                            Notes
                                                        </th>
                                                        <th class="text-left" style="width: 10%">
                                                            Styling
                                                        </th>
                                                        <th class="text-center" style="width: 10%"></th>
                                                        <th class="text-center" style="width: 10%">
                                                            Qty
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds.filter(prd => prd.automation == false)" :key="index"
                                                    >
                                                        <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                                        <td>
                                                            {{ item.productDescription }}

                                                            <v-tooltip 
                                                                bottom
                                                                v-if="item.customOrder == 1"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon 
                                                                        v-on="on"
                                                                        v-if="item.customOrder == 1"
                                                                        style="color: var(--color__alert); margin-left: 5px;" 
                                                                    >
                                                                        mdi-package-variant-plus
                                                                    </v-icon>
                                                                </template>
                                                                <span>Custom Order</span>
                                                            </v-tooltip>

                                                            <v-tooltip 
                                                                bottom
                                                                v-if="item.stylable == 1"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon 
                                                                        v-on="on"
                                                                        v-if="item.stylable == 1"
                                                                        style="color: var(--color__blue); margin-left: 5px;" 
                                                                    >
                                                                        mdi-palette-swatch-outline
                                                                    </v-icon>
                                                                </template>
                                                                <span>Stylable</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td>{{ item.productDetails }}</td>
                                                        <td v-html="item.note"></td>
                                                        <td v-html="item.styling"></td>
                                                        <td>
                                                            <v-tooltip top v-if="item.hasPictures == true">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-if="item.hasPictures == true" color="primary" large v-on="on" @click="showPhotos(item.idNote)">mdi mdi-view-gallery-outline</v-icon>
                                                                </template>
                                                                <span>Click to view the photos</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col
                            v-if="qtyChannels > 0"
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Automation
                                    </v-card-title>

                                    <v-card-text 
                                        style="margin-top: 20px;"
                                    >
                                        <label>Automation Controller</label>

                                        <v-simple-table
                                            fixed-header
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 10%">
                                                            Category
                                                        </th>
                                                        <th class="text-left" style="width: 10%">
                                                            Sub Category
                                                        </th>
                                                        <th class="text-left" style="width: 30%">
                                                            Product
                                                        </th>
                                                        <th class="text-left" style="width: 30%">
                                                            Notes
                                                        </th>
                                                        <th class="text-center" style="width: 10%"></th>
                                                        <th class="text-center" style="width: 10%">
                                                            Qty
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds.filter(prd => prd.automationCenter == 1)" :key="index"
                                                    >
                                                        <td>{{ item.categoryDescription }}</td>
                                                        <td>{{ item.subCategoryDescription }}</td>
                                                        <td>{{ item.productDescription }}</td>
                                                        <td v-html="item.note"></td>
                                                        <td>
                                                            <v-tooltip top v-if="item.hasPictures == true">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-if="item.hasPictures == true" color="primary" large v-on="on" @click="showPhotos(item.idNote)">mdi mdi-view-gallery-outline</v-icon>
                                                                </template>
                                                                <span>Click to view the photos</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>

                                    <v-card-text 
                                        style="margin-top: 20px;"
                                        v-if="qtyChannels > 0"
                                    >
                                        <label>Automation Items</label>

                                        <v-simple-table
                                            fixed-header
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 10%">
                                                            Category
                                                        </th>
                                                        <th class="text-left" style="width: 10%">
                                                            Sub Category
                                                        </th>
                                                        <th class="text-left" style="width: 30%">
                                                            Product
                                                        </th>
                                                        <th class="text-left" style="width: 30%">
                                                            Notes
                                                        </th>
                                                        <th class="text-center" style="width: 10%"></th>
                                                        <th class="text-center" style="width: 10%">
                                                            Qty
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds.filter(prd => prd.automatable == 1)" :key="index"
                                                    >
                                                        <td>{{ item.categoryDescription }}</td>
                                                        <td>{{ item.subCategoryDescription }}</td>
                                                        <td>{{ item.productDescription }}</td>
                                                        <td v-html="item.note"></td>
                                                        <td>
                                                            <v-tooltip top v-if="item.hasPictures == true">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-if="item.hasPictures == true" color="primary" large v-on="on" @click="showPhotos(item.idNote)">mdi mdi-view-gallery-outline</v-icon>
                                                                </template>
                                                                <span>Click to view the photos</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col
                            v-if="listAddendum.length > 0"
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Addendum
                                    </v-card-title>

                                    <v-card-text 
                                        style="margin-top: 20px;"
                                    >
                                        <v-simple-table
                                            fixed-header
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 10%">
                                                            Category
                                                        </th>
                                                        <th class="text-left" style="width: 10%">
                                                            Sub Category
                                                        </th>
                                                        <th class="text-left" style="width: 30%">
                                                            Product
                                                        </th>
                                                        <th class="text-left" style="width: 30%">
                                                            Notes
                                                        </th>
                                                        <th class="text-center" style="width: 10%"></th>
                                                        <th class="text-center" style="width: 10%">
                                                            Qty
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listAddendum" :key="index"
                                                    >
                                                        <td>{{ item.productCategoryDescription }}</td>
                                                        <td>{{ item.productSubCategoryDescription }}</td>
                                                        <td>{{ item.description }}</td>
                                                        <td v-html="item.note"></td>
                                                        <td>
                                                            <v-tooltip top v-if="item.hasPictures == true">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-if="item.hasPictures == true" color="primary" large v-on="on" @click="showPhotos(item.idNote)">mdi mdi-view-gallery-outline</v-icon>
                                                                </template>
                                                                <span>Click to view the photos</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td class="text-center">{{ item.newQty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonDismiss"
							outlined
							@click="dismiss()"
						>
							Dismiss
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 

        <ShowFiles 
            :showFiles.sync="dialogShowFiles.show"
            :showAsCarrousel.sync="dialogShowFiles.showAsCarrousel"
            :reload="dialogShowFiles.reload"
            :listParams="dialogShowFiles.listParams"
            :showFilters="dialogShowFiles.showFilters"
        />
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import ShowFiles from "@/components/File/ShowFiles";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT,
    } from "@/utilities/Enums";

	export default {

        mixins: [Helpers],

        components: {
            ShowFiles
        },

        props: {
			
            idAux: {
                type: Number,
                default: 0
            },
			
            idServiceProject: {
                type: Number,
                default: 0
            },

			showScopeOfWork: {
				default: false
			}
		},
		
        data: () => ({
			
            listProductSelecteds: [],
            listAddendum: [],
            
            dialogShowFiles: {
                show: false,
                showAsCarrousel: true,
                reload: 0,
                listParams: [],
                showFilters: false
            },

            listNote: [],
            
		}),

        watch: {
            async "idAux"() {
				this.loadScopeOfWork();
            }
        },

        computed: {

            qtyChannels() {
                let centralAutomation = this.listProductSelecteds.filter(prd => prd.automationCenter == 1)[0];

                if (centralAutomation != null && centralAutomation != undefined) {
                    return centralAutomation.channelQty;
                }
                else {
                    return 0;
                }
            },
        },

        methods: {
			
            async dismiss() {				
				this.$emit('update:showScopeOfWork', false)
            },

            async checkHasPictures(itemProduct) {
                let idNote = 0;
                let additionalNote = '';
                let hasPictures = false;

                if (itemProduct.note != null && itemProduct.note != undefined) {
                    additionalNote = itemProduct.note + '<br />';
                }

                let listNoteProduct = await this.listNote.filter (not => not.idProduct == itemProduct.idTable);

                if (listNoteProduct != null && listNoteProduct != undefined && listNoteProduct.length > 0) {

                    for (const item of listNoteProduct) {
                        let listAttachments = await this.$store.dispatch("attachmentModule/GetAllByDocument", { 
                            typeAttachment: TYPE_ATTACHMENT.PROJECT.value, 
                            typeDocument: TYPE_DOCUMENT.NOTE.value, 
                            idKey: item.id
                        });

                        if (item.description != null) {
                            idNote = item.id;
                            hasPictures = listAttachments != null && listAttachments != undefined && listAttachments.length > 0 ? true : false;
                            additionalNote += item.description + '<br />';
                        }
                    }
                }
                
                return {
                    idNote,
                    hasPictures,
                    additionalNote
                }
            },

            async loadScopeOfWork() {
                let idServiceProject = this.idServiceProject;

                if (idServiceProject != 0) {

                    this.listNote = await this.$store.dispatch("serviceModule/ListProjectNote", { 
                        idService: 0, 
                        idServiceProject, 
                        onlyCheckExists: false, 
                        idStatus: 0, 
                        idPanel: 0, 
                        idStage: 0, 
                        idUser: 0 
                    });
                
                    this.listProductSelecteds = [];

                    let listTreeViewProduct = await this.$store.dispatch("productModule/ListProductsToScopeOfWork", { id: idServiceProject, type: 'SERVICE', showOnlyCreatedItems: false, fromHistory: false });

                    this.listAddendum = await this.$store.dispatch("serviceModule/ListAddendum", idServiceProject);
                    
                    for (const itemCategory of listTreeViewProduct) {
                        for (const itemSubCategoryOrProduct of itemCategory.children) {
                            
                            if (itemSubCategoryOrProduct.type == 'SubCategory') {

                                for (const itemProduct of itemSubCategoryOrProduct.children) {

                                    if (itemProduct.qty != null && itemProduct.qty != undefined && itemProduct.qty != "0.00" && itemProduct.qty != "" ) {
                                        
                                        let result = await this.checkHasPictures(itemSubCategoryOrProduct);

                                        let qty = parseFloat(itemProduct.qty == '' ? 0 : itemProduct.qty);
                                        
                                        if (qty <= 0) {
                                            this.showToast("error", "Warning!", "Quantity cannot be negative!");
                                            itemProduct.qty = '';
                                        }
                                        else {

                                            this.listProductSelecteds.push({
                                                id: itemProduct.idServiceItem,
                                                idService: idServiceProject,
                                                idCategory: itemCategory.id,
                                                categoryDescription: itemCategory.name,
                                                idSubCategory: itemSubCategoryOrProduct.id,
                                                subCategoryDescription: itemSubCategoryOrProduct.name,
                                                productDescription: itemProduct.name,
                                                idProduct: itemProduct.idTable,
                                                qty: itemProduct.qty,
                                                automation: itemProduct.automatable == 1 || itemProduct.automationCenter == 1,
                                                automatable: itemProduct.automatable,
                                                automationCenter: itemProduct.automationCenter,
                                                channelQty: itemProduct.channelQty,
                                                highlight: itemProduct.highlight,
                                                highlightDescription: itemProduct.highlightDescription,
                                                unitySymbol: itemProduct.unitySymbol,
                                                hasPictures: result.hasPictures,
                                                idNote: result.idNote,
                                                note: result.additionalNote,
                                                styling: itemProduct.styling,
                                                customOrder: itemProduct.customOrder,
                                                stylable: itemProduct.stylable
                                            });
                                        }
                                    }
                                    else {
                                        itemProduct.qty = '';
                                    }
                                }
                            }
                            else {
                                
                                if (itemSubCategoryOrProduct.qty != null && itemSubCategoryOrProduct.qty != undefined && itemSubCategoryOrProduct.qty != "0.00" && itemSubCategoryOrProduct.qty != "" ) {

                                    let qty = parseFloat(itemSubCategoryOrProduct.qty == '' ? 0 : itemSubCategoryOrProduct.qty);

                                    if (qty <= 0) {
                                        this.showToast("error", "Warning!", "Quantity cannot be negative!");
                                        itemSubCategoryOrProduct.qty = ''
                                    }
                                    else {

                                        let result = await this.checkHasPictures(itemSubCategoryOrProduct);

                                        this.listProductSelecteds.push({
                                            id: itemSubCategoryOrProduct.idServiceItem,
                                            idService: idServiceProject,
                                            idCategory: itemCategory.id,
                                            categoryDescription: itemCategory.name,
                                            idSubCategory: 0,
                                            subCategoryDescription: "",
                                            productDescription: itemSubCategoryOrProduct.name,
                                            idProduct: itemSubCategoryOrProduct.idTable,
                                            qty: itemSubCategoryOrProduct.qty,
                                            automation: itemSubCategoryOrProduct.automatable == 1 || itemSubCategoryOrProduct.automationCenter == 1,
                                            automatable: itemSubCategoryOrProduct.automatable,
                                            automationCenter: itemSubCategoryOrProduct.automationCenter,
                                            channelQty: itemSubCategoryOrProduct.channelQty,
                                            highlight: itemSubCategoryOrProduct.highlight,
                                            highlightDescription: itemSubCategoryOrProduct.highlightDescription,
                                            unitySymbol: itemSubCategoryOrProduct.unitySymbol,
                                            hasPictures: result.hasPictures,
                                            idNote: result.idNote,
                                            note: result.additionalNote,
                                            styling: itemSubCategoryOrProduct.styling,
                                            customOrder: itemSubCategoryOrProduct.customOrder,
                                            stylable: itemSubCategoryOrProduct.stylable
                                        });
                                    }
                                }
                                else {
                                    itemSubCategoryOrProduct.qty = ''
                                }
                            }

                            this.$forceUpdate();
                        }
                    }
                }
            },

            async showPhotos(id) {
                
                let listParams = [];
                let stageDescription = '';

                let note = this.listNote.filter (not => not.id == id)[0];

                if (note != null && note != undefined) {
                    stageDescription = note.statusDescription;
                }

                listParams.push({
                    stageDescription: stageDescription,
                    typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                    typeDocument: TYPE_DOCUMENT.NOTE.value,
                    typeAttachmentDescription: '',
                    typeDocumentDescription: '',
                    idKey: note.id,
                    isImage: true
                });

                this.dialogShowFiles = {
                    show: true,
                    showAsCarrousel: true,
                    reload: Math.random(),
                    listParams,
                    showFilters: false
                };
            },
        }
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>