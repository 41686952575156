<template>
    <div>
        <v-row>
            <v-col 
                cols="12"
                :lg="lgWidthTerms"
                md="6"
                sm="12"
            >
                <div v-if="address != null && address != ''" style="font-size: 12px; cursor: pointer; z-index: -1;">
                    <label>See more details for<a style="margin-left: 2px; margin-right: 2px; z-index: -1;" :href="'https://www.zillow.com/homes/' + address" target="_blank">{{ address }}</a>on Zillow</label>
                </div>
                <div class="v-text-field__details" style="margin-top: 0px; font-size: 12px; z-index:0;">
                    <label>© Zillow, Inc., 2006-2020. Use is subject to <a style="z-index:0;" href="https://www.zillow.com/z/corp/terms/" target="_blank">Terms of Use</a></label>
                </div>
                <div class="v-text-field__details" style="margin-top: 0px; font-size: 12px; z-index:0;">
                    <label>Disclaimer: the data is provided “as is” via the Zestimate API</label>
                </div>
            </v-col> 
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="12"
                :lg="lgWidthLogo"
                md="5"
                sm="12"
            >
                <div>
                    <a href="https://zillow.com" target="_blank"><v-img style="width: 10rem" :src="require('@/assets/images/zillow_logo.svg')" /></a>
                </div> 
            </v-col> 
        </v-row> 
    </div>
</template>

<script>
    export default {
        props: {
            address: {
                type: String,
                default: null
            },
            lgWidthTerms: {
                type: String,
                default: "3"
            },
            lgWidthLogo: {
                type: String,
                default: "3"
            }
        }
    }
</script>
